<template>
  <div>
    <v-container>
      <v-layout justify-space-between align-center wrap>
        <v-flex xs12 sm6 class="my-12">
          <h2 class="text-h3 font-weight-medium text-uppercase">
            Organize the present,<br />plan the future,<br />track the past.
          </h2>
          <p class="text-h5 mt-4">
            • Bullet is the world’s first productivity app that lets you
            organize your tasks, notes and events all in one place.
          </p>

          <v-btn color="primary" x-large :to="{ name: 'register' }">
            Get Started
          </v-btn>
        </v-flex>
        <v-flex class="my-12" xs12 sm5>
          <img
            class="responsive-img"
            src="@/assets/img/landing/open-doodles-sitting-and-reading.png"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <div class="primary white--text">
      <v-container class="d-flex align-center justify-center flex-wrap">
        <a
          href="https://www.producthunt.com/posts/bullet-5?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-bullet-5"
          target="_blank"
          class="mr-4"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=179847&theme=light"
            alt="• Bullet - A new year, a new todo app - based on bullet journaling. | Product Hunt Embed"
            style="width: 250px; height: 54px; display: block;"
            width="250px"
            height="54px"
          />
        </a>
        <div class="text-h6 text-center pt-4 pt-sm-0">
          #4 app of the day on Product Hunt
        </div>
      </v-container>
    </div>
    <div class="white black--text">
      <v-container>
        <v-card class="mt-12" elevation="10">
          <v-carousel height="auto" cycle :show-arrows="false">
            <v-carousel-item
              src="@/assets/img/landing/weekly.png"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/img/landing/task.png"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/img/landing/monthly.png"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
        <div class="mb-12 caption pa-4 text-center">
          Bullet Journaling is a popular method of
          <a href="https://www.youtube.com/watch?v=fm15cmYU0IM" target="_blank"
            >organizing your tasks, notes & events</a
          >. • Bullet is your digital bullet journal.
        </div>

        <!-- <h2 class="text-h4 font-weight-medium py-12 text-center text-uppercase">
          Task management for busy people
        </h2> -->

        <v-layout class="my-12" justify-center align-center wrap>
          <v-flex xs12 sm5 class="pl-sm-12 order-sm-1">
            <h3 class="text-h4 font-weight-bold mb-4">Be Zen</h3>
            <p class="text-h5">
              Keep productivity flowing using a simple method designed to make
              journaling as unobtrusive as possible.
            </p>
            <p class="text-h5">
              Open • Bullet and add your first task in seconds.
            </p>
          </v-flex>
          <v-flex class="mt-4 order-sm-0" xs12 sm4>
            <img
              class="responsive-img"
              src="@/assets/img/landing/open-doodles-meditating.png"
            />
          </v-flex>
        </v-layout>

        <v-layout class="my-12" justify-center align-center wrap>
          <v-flex xs12 sm6 class="pr-sm-12">
            <h3 class="text-h4 font-weight-bold mb-4">Stay Focused</h3>
            <p class="text-h5">
              Productivity tools are either too complicated or inflexible, and
              constantly disrupt your workflow.
            </p>
            <p class="text-h5">
              With • Bullet you can organize and schedule tasks, notes and
              events exactly how you like.
            </p>
          </v-flex>
          <v-flex xs12 sm3>
            <img
              class="responsive-img"
              src="@/assets/img/landing/open-doodles-reading.png"
            />
          </v-flex>
        </v-layout>

        <v-layout class="my-12" justify-center align-center wrap>
          <v-flex xs12 sm5 class="pl-sm-12 order-sm-1">
            <h3 class="text-h4 font-weight-bold mb-4">Free Your Mind</h3>
            <p class="text-h5">
              Unload your thoughts and todos into • Bullet and allow your mind
              to focus on what's important.
            </p>
          </v-flex>
          <v-flex class="order-sm-0" xs12 sm4>
            <img
              class="responsive-img"
              src="@/assets/img/landing/open-doodles-roller-skating.png"
            />
          </v-flex>
        </v-layout>

        <v-layout class="my-12" justify-center align-center wrap>
          <v-flex xs12 sm6 class="pr-sm-12">
            <h3 class="text-h4 font-weight-bold mb-4">Free Your Body</h3>
            <p class="text-h5">
              • Bullet goes wherever you go. It works just as well on your
              mobile as it does on desktop.
            </p>
          </v-flex>
          <v-flex xs12 sm3>
            <img
              class="responsive-img"
              src="@/assets/img/landing/open-doodles-selfie.png"
            />
          </v-flex>
        </v-layout>

        <div class="my-12 d-flex justify-space-between align-center flex-wrap">
          <v-flex xs12 sm8 class="order-sm-1">
            <v-card elevation="10" class="mt-12">
              <v-img src="@/assets/img/landing/monthly.png"></v-img>
            </v-card>
          </v-flex>
          <v-flex xs12 sm4 class="mt-4 order-sm-0">
            <ul>
              <li>Daily, weekly and monthly logs</li>
              <li>Supports tasks, notes and events</li>
              <li>Set tasks for specific days, weeks or months</li>
              <li>Organize with lists or tags</li>
              <li>And much more...</li>
            </ul>
          </v-flex>
        </div>

        <v-layout justify-center class="my-12">
          <v-btn color="primary" x-large :to="{ name: 'register' }">
            Get Started
          </v-btn>
        </v-layout>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'

export default {
  components: {
    Footer,
  },
}
</script>

<style lang="scss" scoped>
.display-image {
  position: absolute;
}
</style>
