<template>
  <div class="d-flex justify-center mt-5">
    <v-card class="forgot-password-card">
      <v-card-title>{{ $t('views.forgot-password.title') }}</v-card-title>

      <v-divider />

      <v-card-text>
        <v-form class="my-3" ref="form" @submit.prevent="submit">
          <v-text-field
            v-model="email"
            type="email"
            :label="$t('views.forgot-password.email-address')"
            :rules="rules.email"
            outlined
          ></v-text-field>

          <v-btn type="submit" block class="primary" :loading="loading">
            {{ $t('views.forgot-password.send-password-reset') }}
          </v-btn>
        </v-form>

        <router-link :to="{ name: 'login' }" class="d-block">
          {{ $t('views.forgot-password.login') }}
        </router-link>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { auth } from '@/firebase'
import { email } from '@/util/vuetify-rules'

export default {
  data() {
    return {
      email: '',
      loading: false,
    }
  },

  computed: {
    rules() {
      return {
        email,
      }
    },
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true

        await auth
          .sendPasswordResetEmail(this.email)
          .then(() =>
            alert(
              this.$t('views.forgot-password.success-message', {
                email: this.email,
              })
            )
          )
          .catch(err => alert(err))

        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.forgot-password-card {
  width: 100%;
  max-width: 320px;
}
</style>
