<template>
  <div class="d-flex justify-center mt-5">
    <v-card class="login-card">
      <v-card-title>{{ $t('views.login.title') }}</v-card-title>

      <v-divider />

      <v-card-text>
        <v-form class="my-3" ref="form" @submit.prevent="submit">
          <v-text-field
            v-model="email"
            type="email"
            label="Email Address"
            :rules="rules.email"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="password"
            type="password"
            label="Password"
            :rules="rules.password"
            outlined
          ></v-text-field>

          <v-btn :loading="loading" type="submit" block class="primary">
            {{ $t('app.login') }}
          </v-btn>
        </v-form>

        <router-link :to="{ name: 'forgot-password' }" class="d-block">
          {{ $t('app.forgot-password') }}
        </router-link>

        <router-link :to="{ name: 'register' }" class="d-block">
          {{ $t('app.register') }}
        </router-link>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { email, password } from '@/util/vuetify-rules'

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    }
  },

  computed: {
    rules() {
      return {
        email,
        password,
      }
    },
  },

  methods: {
    ...mapActions('auth', ['login']),

    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true

        const payload = {
          email: this.email,
          password: this.password,
        }

        await this.login(payload)

        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login-card {
  width: 100%;
  max-width: 320px;
}
</style>
